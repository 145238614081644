import { flowRight } from 'lodash';
import { connect } from '../components/runtime-context';

import withHocName from './with-hoc-name';
import withLayoutProps from './with-layout-props';
import {
  getFontSize,
  getDefaultFontSizeDesktop,
} from '../selectors/app-settings-selectors';
import withExperiment from './with-experiment';
import { EXPERIMENT_FONT_SIZE_FIX_ENABLED } from '@wix/communities-blog-experiments';

const mapRuntimeToProps = (
  state,
  { section, layoutType, isFontFixEnabled },
) => {
  return {
    postTitleFontSize: getFontSize(state, layoutType, section, 'title'),
    postDescriptionFontSize: getFontSize(
      state,
      layoutType,
      section,
      'description',
    ),
    postMetadataFontSize: getFontSize(state, layoutType, section, 'metadata'),
    defaultPostTitleFontSize:
      isFontFixEnabled &&
      getDefaultFontSizeDesktop(state, layoutType, section, 'titleFont'),
    defaultPostDescriptionFontSize:
      isFontFixEnabled &&
      getDefaultFontSizeDesktop(state, layoutType, section, 'descriptionFont'),
  };
};

export default flowRight(
  withLayoutProps(),
  withExperiment({
    isFontFixEnabled: EXPERIMENT_FONT_SIZE_FIX_ENABLED,
  }),
  connect(mapRuntimeToProps),
  withHocName('WithPostFontSize'),
);
